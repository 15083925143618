import { notification } from "antd";

const useNotification = () => {
  const [api, contextHolder] = notification.useNotification();
  const showNotification = ({ type, description, duration }) => {
    api[type]({
      description: description,
      placement: "topRight",
      duration: duration || 2,
    });
  };

  return [showNotification, contextHolder];
};

export default useNotification;
