import React from "react";
import { Table, Button } from "antd";

const columns = [
  {
    title: "Service Name",
    dataIndex: "name",
  },
  {
    title: "Category",
    dataIndex: "category",
  },
  {
    title: "Service Description",
    dataIndex: "provider",
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button type="primary" style={{ marginBottom: "10px" }}>
          More details
        </Button>
        <Button type="primary">Remove</Button>
      </div>
    ),
  },
];
const data = [
  {
    key: "1",
    id: "1",
    name: "Dog Walking Services",
    category: "Pet",
    provider:
      "Professional dog walkers provide regular exercise and mental stimulation for dogs.",
  },
  {
    key: "2",
    id: "2",
    name: "Pet Grooming Services",
    category: "Pet",
    provider:
      "Skilled groomers offer a range of grooming services such as bathing, brushing, nail trimming, and coat styling for pets.",
  },
  {
    key: "3",
    id: "3",
    name: "Pet Boarding Services",
    category: "Pet",
    provider:
      "Pet boarding facilities provide a safe and comfortable environment for pets when their owners are away.",
  },
];

export default function CustomerWishList() {
  return (
    <>
      <h1 className="fas__borderbottom" style={{ marginBottom: "40px" }}>
        My Favourite List
      </h1>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered={true}
        style={{ marginBottom: "20px" }}
        rowKey="id"
      />
    </>
  );
}
