import React from "react";
import "./Providerintroduction.css";
import { Rate } from "antd";
import { Card, Avatar } from "antd";
import { FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";

const customIcons = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};

const { Meta } = Card;

export default function Providerintroduction(props) {
  return (
    <div className="fas_dp-pi">
      <Card>
        <h1 className="fas__borderbottom">About Provider</h1>
        <Meta
          avatar={<Avatar src={props.data[0].photo} size={64} />}
          title={props.data[0].name}
          description={
            <span>
              <Rate
                defaultValue={props.data[0].star}
                character={({ index }) => customIcons[index + 1]}
                disabled
                allowHalf
              />
              <p>{props.data[0].about}</p>
            </span>
          }
        />
      </Card>
    </div>
  );
}
