import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { Select, Space, Table, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import StatCard from "../../../../components/StatCard/StatCard";
import { getAPI, putAPI } from "../../../../util/api";
import useUserData from "../../../../hooks/useUserData";
import useNotification from "../../../../hooks/useNotification";

let statCardData = [
  {
    title: "Pending Orders",
    number: 1,
    icon: <ClockCircleOutlined />,
    bgColor: "orange",
  },
  {
    title: "Accepted Orders",
    number: 0,
    icon: <RightCircleOutlined />,
    bgColor: "yellow",
  },
  {
    title: "Completed Orders",
    number: 4,
    icon: <CheckCircleOutlined />,
    bgColor: "green",
  },
  {
    title: "Rejected Orders",
    number: 0,
    icon: <CloseCircleOutlined />,
    bgColor: "grey",
  },
];

const Orders = () => {
  const [showNotification, contextHolder] = useNotification();

  const { userData } = useUserData();
  const [tableData, setTableData] = useState();
  const getTableData = useCallback(() => {
    getAPI(`order/provider/${userData._id}`).then((data) => setTableData(data));
  }, [userData._id]);
  useEffect(() => {
    getTableData();
  }, [getTableData]);

  // Drop down list - Change user status
  const onStatus = (value, _id) => {
    putAPI(`order/`, { _id, status: value }).then(() => {
      showNotification({
        type: "success",
        description: "Set status successfully!",
      });
      getTableData();
    });
  };

  const columns = [
    {
      title: "Service Name",
      dataIndex: ["sid", "name"],
      width: 140,
      fixed: "left",
    },
    {
      title: "Customer Name",
      dataIndex: ["cid", "name"],
      width: 140,
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "request further details":
            color = "cyan";
            break;
          case "rejected":
            color = "red";
            break;
          case "completed":
            color = "green";
            break;
          case "accepted":
            color = "lime";
            break;
          case "pending":
            color = "default";
            break;
          default:
            color = "default";
        }
        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Note",
      dataIndex: "note",
    },
    {
      title: "Create Date",
      dataIndex: "createdAt",
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: "Finish Date",
      dataIndex: "finish_date",
      render: (finish_date) =>
        finish_date ? new Date(finish_date).toLocaleString() : "",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <Select
            defaultValue={record.status}
            onChange={(value) => onStatus(value, record._id)}
            style={{ width: 160 }}
            options={[
              {
                value: "pending",
                label: "pending",
              },
              {
                value: "accepted",
                label: "accepted",
              },
              {
                value: "completed",
                label: "completed",
              },
              {
                value: "rejected",
                label: "rejected",
              },
              {
                value: "request further details",
                label: "request further details",
              },
            ]}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}

      <div>
        <StatCard data={statCardData} />

        <Table
          columns={columns}
          dataSource={tableData}
          rowKey="_id"
          pagination={false}
          scroll={{
            x: 1100,
          }}
        />
      </div>
    </>
  );
};

export default Orders;
