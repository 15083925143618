import photo1 from "../img/photo1.jpg";
import photo2 from "../img/photo2.jpg";
import photo3 from "../img/photo3.jpg";
import photo4 from "../img/photo4.jpg";

const CarouselData = [
  {
    cover: photo1,
    title: "Make pet beauty services finding easier!",
  },
  {
    cover: photo2,
    title: "Make cleaning services finding easier!",
  },
  {
    cover: photo3,
    title: "Make wedding services finding easier!",
  },
  {
    cover: photo4,
    title: "Make skin beauty services finding easier!",
  },
];

export default CarouselData;
