const providerinfo = [
  {
    id: "1",
    service_name: "Pet Caring Service",
    name: "Shisong Huang",
    photo:
      "https://images.unsplash.com/photo-1683588775595-07585fdaa097?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    describe:
      "At Pet Caring Service, we are dedicated to providing exceptional care and services for your pets.",
    email: "happyjack@gmail.com",
    phone_number: "+44 1234567894",
    availability: "24/7",
    address: "123 Maple Avenue, Southampton, United Kindom",
    passcode: "SO17 0DG",
    about: "Hello! Welcome to my shop! Please feel free to select our service!",
    star: "4.5",
  },
];

export default providerinfo;
