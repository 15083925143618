import React, { useEffect, useState } from "react";
import "./ServiceList.css";
import { FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";
import { Card, Space, Radio, Rate, Empty, Spin } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAPI } from "../../../util/api";

const customIcons = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};
const { Meta } = Card;

export default function ServiceList() {
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const category = search.get("category") || "";
  const address = search.get("address") || "";

  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const serviceData = services.filter(
    (item) =>
      (category ? item.category === category : true) &&
      (address ? item.address === address : true)
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getAPI("service").then((data) => {
      setIsLoading(false);
      setServices(data);
    });
  }, []);

  return (
    <div id="fas__servicelist">
      <div className="fas__filter">
        <Space direction="vertical" size={16}>
          <Card
            title="Category"
            style={{
              width: 300,
            }}
          >
            <Radio.Group
              onChange={(e) =>
                setSearch(`category=${e.target.value}&address=${address}`)
              }
              name="category"
              value={category}
            >
              <Space direction="vertical">
                <Radio value="">Any</Radio>
                <Radio value="Pet">Pet</Radio>
                <Radio value="Cleaning">Cleaning</Radio>
                <Radio value="Baby Sitting">Baby Sitting</Radio>
                <Radio value="Pest Control">Pest Control</Radio>
                <Radio value="Plumbing">Plumbing</Radio>
                <Radio value="Electrical Repairs">Electrical Repairs</Radio>
                <Radio value="Beauty">Beauty</Radio>
                <Radio value="Decoration">Decoration</Radio>
              </Space>
            </Radio.Group>
          </Card>
          <Card
            title="Address"
            style={{
              width: 300,
            }}
          >
            <Radio.Group
              onChange={(e) =>
                setSearch(`category=${category}&address=${e.target.value}`)
              }
              name="address"
              value={address}
            >
              <Space direction="vertical">
                <Radio value="">Any</Radio>
                <Radio value="London">London</Radio>
                <Radio value="Southampton">Southampton</Radio>
                <Radio value="Edinburgh">Edinburgh</Radio>
                <Radio value="Manchester">Manchester</Radio>
              </Space>
            </Radio.Group>
          </Card>
        </Space>
      </div>

      {!isLoading ? (
        <>
          {serviceData.length !== 0 ? (
            <div className="fas__servicelist">
              {serviceData.map((service) => (
                <Card
                  onClick={() => navigate(`/details/${service._id}`)}
                  key={service._id}
                  className="fas__service"
                  hoverable
                  cover={<img alt="img" src={service.img} />}
                >
                  <Meta
                    title={service.name}
                    style={{
                      height: "50px",
                      alignItems: "center",
                      margin: "10px",
                    }}
                    description={
                      <span>
                        <p
                          style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "#FF8A00",
                          }}
                        >
                          ￡{service.price}
                        </p>
                        <Rate
                          character={({ index }) => customIcons[index + 1]}
                          disabled
                          defaultValue={service.rating}
                          allowHalf
                        />
                        <p
                          style={{
                            display: "inline-block",
                            marginLeft: "10px",
                          }}
                        >
                          {service.rating}
                        </p>
                      </span>
                    }
                  />
                </Card>
              ))}
            </div>
          ) : (
            <Empty description={<span>No search data</span>} />
          )}
        </>
      ) : (
        <Spin tip="Loading" size="large" />
      )}
    </div>
  );
}
