import React, { useEffect, useState } from "react";
import { Rate, Space, Table } from "antd";
import { getAPI } from "../../../../util/api";
import useUserData from "../../../../hooks/useUserData";

const Reviews = () => {
  const { userData } = useUserData();

  // Table data
  const [tableData, setTableData] = useState();
  console.log(tableData);

  useEffect(() => {
    getAPI(`review/provider/${userData._id}`).then((data) =>
      setTableData(data)
    );
  }, [userData._id]);

  const columns = [
    {
      title: "Service Name",
      dataIndex: ["oid", "sid", "name"],
    },
    {
      title: "Customer Name",
      dataIndex: ["oid", "cid", "name"],
    },
    {
      title: "Review",
      dataIndex: "content",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <Rate disabled allowHalf defaultValue={record.rating} />
        </Space>
      ),
    },
  ];

  return (
    <div id="fas__provider_request">
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey="_id"
        pagination={false}
      />
    </div>
  );
};

export default Reviews;
