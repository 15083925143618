import {
  LogoutOutlined,
  UserOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Dropdown, Button } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/Logo.jpg";
import "./Navbar.css";
import useNotification from "../../hooks/useNotification";
import useUserData from "../../hooks/useUserData";

const Navbar = () => {
  const navigate = useNavigate();

  const [showNotification, contextHolder] = useNotification();

  const { userData, setUserData } = useUserData();

  // logout
  const handleLogout = () => {
    setUserData();
    showNotification({
      type: "success",
      description: "Log Out successfully!",
    });
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  let link, text;
  switch (userData?.role) {
    case "customer":
      link = "/account";
      text = "User";
      break;
    case "provider":
      link = "/backend/provider/account";
      text = "Provider";
      break;
    case "admin":
      link = "/backend/admin/account";
      text = "Admin";
      break;
    default:
  }

  const items = [
    {
      key: "1",
      label: <Link to={link}>{text} Account</Link>,
      icon: <UserOutlined />,
    },
    {
      key: "2",
      label: <div onClick={handleLogout}>Log Out</div>,
      icon: <LogoutOutlined />,
    },
  ];

  if (userData?.role === "customer") {
    items.splice(1, 0, {
      key: "3",
      label: <Link to="/account/order">My Order</Link>,
      icon: <UnorderedListOutlined />,
    });
  }

  return (
    <>
      {contextHolder}
      <nav id="fas__navbar">
        <Link to="/" className="fas__navbar-logo">
          <img src={Logo} alt="Logo" />
        </Link>

        <div className="fas__navbar-func">
          {userData ? (
            <Dropdown
              menu={{
                items,
              }}
              placement="topRight"
            >
              <div className="fas__dropdown">
                <div className="fas__navbar-avatar">
                  <UserOutlined />
                </div>
                <p className="fas__navbar-hello fas__font-georgia">
                  <span
                    style={
                      userData?.role !== "customer" ? { color: "#ff8a00" } : {}
                    }
                  >
                    {userData?.name}
                  </span>
                </p>
              </div>
            </Dropdown>
          ) : (
            <Button
              type="primary"
              icon={<LogoutOutlined />}
              onClick={() => navigate("/auth/login")}
            >
              <span className="fas__dropdown-span">Login</span>
            </Button>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
