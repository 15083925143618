import React from "react";
import { Carousel, FloatButton, Space } from "antd";
import {
  GiftOutlined,
  WhatsAppOutlined,
  FieldTimeOutlined,
  LikeOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import "./Home.css";
import Advantage from "./Advantages/Advantages.jsx";
import CarouselData from "./Data/CarouselData.js";
import SelectServices from "./SelectServices/SelectServices.jsx";

const slogan = {
  textAlign: "center",
  color: "#ff8a00",
  fontWeight: "Bold",
  fontSize: "1.5rem",
};

const Section = (props) => {
  return (
    <section className="fas__section">
      <h1>{props.title}</h1>
      {props.children}
    </section>
  );
};

const Home = () => {
  return (
    <div className="fas__home">
      <FloatButton.BackTop icon={<CaretUpOutlined />} />

      <Carousel autoplay effect="fade">
        {CarouselData.map((item) => (
          <div key={item.title} className="fas__home-carousel">
            <div className="fas__home-carousel-flex">
              <img src={item.cover} alt="Carousel cover" />

              <div className="fas__home-carousel-right">
                <h1>{item.title}</h1>
              </div>
            </div>
          </div>
        ))}
      </Carousel>

      <Section title="Good Service">
        <p style={slogan}>
          All businesses' services have been strictly reviewed by us,
        </p>
        <p style={slogan}>You can find any satisfactory service here!</p>
      </Section>

      <Section title="Select Service">
        <SelectServices />
      </Section>

      <Section title="Our Advantages">
        <div className="our_advantages_wrap">
          <div className="our_advantages_content">
            <Space>
              <GiftOutlined />
            </Space>
            <Advantage
              name="High-quality Service"
              content="Each provider has been approved. If their service is given bad reviews their service will be removed"
            />
          </div>
          <div className="our_advantages_content">
            <Space>
              <WhatsAppOutlined />
            </Space>
            <Advantage
              name="Keep in Contact"
              content="You can keep in touch with provider at any time"
            />
          </div>
          <div className="our_advantages_content">
            <Space>
              <FieldTimeOutlined />
            </Space>
            <Advantage
              name="Track Your Service"
              content="You can view the service status at any time. Does the service start? What is the step?"
            />
          </div>
          <div className="our_advantages_content">
            <Space>
              <LikeOutlined />
            </Space>
            <Advantage
              name="Confirm Order"
              content="When the service is complete and correct, you can click the complete service completion button."
            />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Home;
