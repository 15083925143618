import { useState } from "react";

const useUserData = () => {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  if (userData) {
    localStorage.setItem("userdata", JSON.stringify(userData));
  } else {
    localStorage.removeItem("userdata");
  }

  return { userData, setUserData };
};

export default useUserData;
