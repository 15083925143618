import React, { useEffect, useState } from "react";
import { Card, Avatar, Rate, Empty } from "antd";
import "./Comments.css";
import { FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { getAPI } from "../../../../util/api";
const customIcons = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};
const { Meta } = Card;
export default function Comments() {
  // Get service ID
  const { id } = useParams();

  // // Get service comments
  const [commentData, setCommentData] = useState();

  useEffect(() => {
    getAPI(`review/service/${id}`).then((data) => {
      setCommentData(data);
    });
  }, [id]);

  return (
    <>
      {commentData?.length > 0 ? (
        commentData.map((item) => {
          return (
            <Card className="commentsItem" key={`commentsItem-${item._id}`}>
              <Meta
                avatar={<Avatar src={item.oid.cid.avatar} size={64} />}
                title={item.oid.cid.name}
                description={
                  <span>
                    <p style={{ display: "inline-block", marginRight: "15px" }}>
                      {new Date(item.createdAt).toLocaleString()}
                    </p>
                    <Rate
                      character={({ index }) => customIcons[index + 1]}
                      disabled
                      defaultValue={item.rating}
                      allowHalf
                    />
                  </span>
                }
              ></Meta>
              <div
                style={{ marginLeft: "7.5%", marginTop: "2%", maxWidth: "90%" }}
              >
                <p style={{ marginBottom: "2%" }}>{item.content}</p>
              </div>
            </Card>
          );
        })
      ) : (
        <Empty />
      )}
    </>
  );
}
