import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Space,
  Modal,
  Input,
  InputNumber,
  Button,
  Form,
  Tag,
  Rate,
} from "antd";
import { deleteAPI, getAPI, postAPI, putAPI } from "../../../../../util/api";
import {
  CloseOutlined,
  CommentOutlined,
  EditOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useUserData from "../../../../../hooks/useUserData";
import useNotification from "../../../../../hooks/useNotification";

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};

export default function Orders() {
  const navigate = useNavigate();
  const [showNotification, contextHolder] = useNotification();
  const { userData } = useUserData();

  const [tableData, setTableData] = useState();
  const getTableData = useCallback(() => {
    getAPI(`order/customer/${userData._id}`).then((data) => setTableData(data));
  }, [userData._id]);
  useEffect(() => {
    getTableData();
  }, [getTableData]);

  // Dialogs - Edit Note
  const [openNote, setOpenNote] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [formNote] = Form.useForm();
  const showModalNote = (record) => {
    setOpenNote(true);
    formNote.setFieldsValue(record);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpenNote(false);
      setOpenComment(false);
      setConfirmLoading(false);
    }, 1000);
  };
  const handleCancel = () => {
    setOpenNote(false);
    setOpenComment(false);
  };

  const onFinishNote = (values) => {
    putAPI("order", { _id: formNote.getFieldValue("_id"), ...values }).then(
      () => {
        handleOk();
        getTableData();
        showNotification({
          type: "success",
          description: `${values ? "Edit" : "Add"} a note successfully!`,
        });
      }
    );
  };

  const onCancel = (id) => {
    deleteAPI(`order/${id}`).then(() => {
      getTableData();
      showNotification({
        type: "success",
        description: "Cancel Successfully!",
      });
    });
  };

  // Dialogs - Edit Comment
  const [openComment, setOpenComment] = useState(false);
  const [formComment] = Form.useForm();
  const showModalComment = (record) => {
    formComment.setFieldsValue(record);
    setOpenComment(true);
  };

  const onFinishComment = (values) => {
    postAPI("review", {
      oid: formComment.getFieldValue("_id"),
      ...values,
    }).then(() => {
      handleOk();
      getTableData();
      showNotification({
        type: "success",
        description: "Comment successfully!",
      });
    });
  };

  const columns = [
    {
      title: "Service Name",
      dataIndex: ["sid", "name"],
      width: 150,
      fixed: "left",
    },
    {
      title: "Note",
      dataIndex: "note",
      render: (note) => note || <span style={{ color: "grey" }}>No Note</span>,
    },
    {
      title: "Comment",
      dataIndex: "content",
      render: (review) =>
        review || <span style={{ color: "grey" }}>No Comment</span>,
    },
    {
      title: "Rating",
      dataIndex: "rating",
      render: (rating) =>
        rating ? (
          <Space size="middle">
            <Rate disabled allowHalf defaultValue={rating} />
          </Space>
        ) : (
          <span style={{ color: "grey" }}>No Rating</span>
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "request further details":
            color = "cyan";
            break;
          case "rejected":
            color = "red";
            break;
          case "completed":
            color = "green";
            break;
          case "accepted":
            color = "lime";
            break;
          case "pending":
            color = "default";
            break;
          default:
            color = "default";
        }
        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Create Date",
      dataIndex: "createdAt",
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: "Finish Date",
      dataIndex: "finish_date",
      render: (finish_date) =>
        finish_date ? new Date(finish_date).toLocaleString() : "",
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      fixed: "right",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {record.status === "completed" ? (
            <>
              <Button
                style={{ marginTop: "10px" }}
                type="primary"
                onClick={() => showModalComment(record)}
                icon={<CommentOutlined />}
                disabled={record.content}
              >
                Comment
              </Button>
              <Button
                type="primary"
                style={{ marginTop: "10px" }}
                onClick={() => navigate("/invoice")}
                icon={<PrinterOutlined />}
              >
                Invoice
              </Button>
            </>
          ) : (
            <>
              <Button
                type="primary"
                onClick={() => showModalNote(record)}
                icon={<EditOutlined />}
              >
                {record.note ? "Edit" : "Add"} a note
              </Button>
              <Button
                style={{ marginTop: "10px" }}
                onClick={() => onCancel(record._id)}
                icon={<CloseOutlined />}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {contextHolder}

      <h1 className="fas__borderbottom" style={{ marginBottom: "40px" }}>
        My Order
      </h1>

      <Table
        columns={columns}
        dataSource={tableData}
        rowKey="_id"
        pagination={false}
        scroll={{
          x: 1200,
        }}
      />

      {/* Dialogs - Note */}
      <Modal
        title="Edit Note"
        open={openNote}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
      >
        <Form {...layout} onFinish={onFinishNote} form={formNote}>
          <Form.Item name="note" label="Note">
            <Input />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              ...layout.wrapperCol,
              offset: 6,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Dialogs - comment */}
      <Modal
        title="Edit Comment"
        open={openComment}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
      >
        <Form {...layout} onFinish={onFinishComment} form={formComment}>
          <Form.Item name="content" label="Comment">
            <Input />
          </Form.Item>
          <Form.Item name="rating" label="Rating">
            <InputNumber min={1} max={5} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              ...layout.wrapperCol,
              offset: 6,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
