import React from "react";
import { Button, Form, Input } from "antd";
import "./Account.css";
import { putAPI } from "../../../../util/api";
import useNotification from "../../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import useUserData from "../../../../hooks/useUserData";

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};

const Account = () => {
  const navigate = useNavigate();
  // Get user info from localstorage
  const { userData, setUserData } = useUserData();
  const [showNotification, contextHolder] = useNotification();

  const onFinish = (values) => {
    putAPI(`admin`, { _id: userData._id, ...values }).then((data) => {
      showNotification({
        type: "success",
        description: "Edit Info Successfully!",
      });
      setUserData({ ...data, role: userData.role });
      setTimeout(() => {
        navigate(0);
      }, 2000);
    });
  };

  return (
    <>
      {contextHolder}

      <div id="fas__admin-account">
        <h1 className="fas__borderbottom">My Account</h1>
        <Form
          {...layout}
          name="account-form"
          onFinish={onFinish}
          style={{
            width: 600,
          }}
          initialValues={userData}
        >
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ type: "email" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="pwd" label="Password">
            <Input.Password />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              ...layout.wrapperCol,
              offset: 6,
            }}
          >
            <Button type="primary" htmlType="submit">
              Edit Info
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Account;
