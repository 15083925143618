import React, { useEffect } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import {
  CommentOutlined,
  DashboardOutlined,
  OrderedListOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const menuData = [
  getItem("Dashboard", "dashboard", <DashboardOutlined />),
  getItem("Orders", "orders", <OrderedListOutlined />),
  getItem("Services", "services", <ShoppingOutlined />),
  getItem("Comments", "reviews", <CommentOutlined />),
  getItem("My Account", "account", <UserOutlined />),
];

const Provider = () => {
  const [setMenuItem] = useOutletContext();

  useEffect(() => {
    setMenuItem(menuData);
  });

  return (
    <>
      <Outlet />
    </>
  );
};

export default Provider;
