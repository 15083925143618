import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getAPI } from "../../../../util/api";

const Services = () => {
  // Table data
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    getAPI("service").then((data) => setTableData(data));
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Photo",
      dataIndex: "photo",
      render: (_, { img }) => <img className="table_img" src={img} alt="img" />,
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Addresss",
      dataIndex: "address",
    },
    {
      title: "description",
      dataIndex: "desc",
    },
  ];

  return (
    <div id="admin_service">
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey="_id"
        pagination={false}
      />
    </div>
  );
};

export default Services;
