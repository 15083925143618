import React from "react";
import "./Footer.css";
import Logo from "../../assets/Logo.jpg";
import { Link } from "react-router-dom";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

const FooterLinksData = [
  {
    title: "About Us",
    links: [
      {
        subtitle: "Home",
        path: "/",
      },
      {
        subtitle: "All Services",
        path: "/service_list",
      },
    ],
  },
  {
    title: "Others",
    links: [
      {
        subtitle: "Contact Us",
        path: "/contact",
      },
      {
        subtitle: "Privacy Policy",
        path: "/privacy",
      },
      {
        subtitle: "Term and Conditions",
        path: "/terms",
      },
    ],
  },
];

const FooterLinks = () => {
  return (
    <>
      {FooterLinksData.map((group, index) => (
        <ul key={`footer-group-${index}`} className="fas__footer-main-group">
          <li className="fas__footer-main-group-title">{group.title}</li>
          {group.links.map((link, index) => (
            <li
              className="fas__footer-main-group-link"
              key={`footer-group-link-${index}`}
            >
              <Link className="fas__link-text " to={link.path}>
                {link.subtitle}
              </Link>
            </li>
          ))}
        </ul>
      ))}
    </>
  );
};

const Footer = () => {
  return (
    <footer id="fas__footer">
      <div className="fas__footer-main">
        <div className="fas__footer-main-left">
          <div className="fas__footer-main-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <p className="fas__footer-main-desc">
            "Find a Service" is required to help customers find a trustworthy
            service provider in their local area. The application can offer
            services such as cleaning, babysitting, pest control, plumbing,
            electrical repairs, beauty, or other services under one platform.
          </p>
        </div>

        <div className="fas__footer-main-right">
          <FooterLinks />
        </div>
      </div>

      <div className="fas__footer-banner">
        <div className="fas__footer-banner-copyright">
          © 2023{" "}
          <Link
            to="https://harriswong.top"
            target="_blank"
            className="fas__link-text"
          >
            Harris Wong
          </Link>
          . All rights reserved
        </div>

        <ul className="fas__footer-banner-social">
          <li>
            {" "}
            <TwitterOutlined />{" "}
          </li>
          <li>
            {" "}
            <InstagramOutlined />{" "}
          </li>
          <li>
            {" "}
            <FacebookOutlined />{" "}
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
