import React from "react";
import {
  PoundOutlined,
  ProfileOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ReactECharts from "echarts-for-react";
import StatCard from "../../../../components/StatCard/StatCard";

let statCardData = [
  {
    title: "Total Customers",
    number: 12,
    icon: <UserOutlined />,
    bgColor: "orange",
  },
  {
    title: "Total Services",
    number: 13,
    icon: <ShoppingOutlined />,
    bgColor: "blue",
  },
  {
    title: "Total Orders",
    number: 15,
    icon: <ProfileOutlined />,
    bgColor: "green",
  },
  {
    title: "Total Turnover",
    number: 2340,
    icon: <PoundOutlined />,
    bgColor: "yellow",
  },
];

const Dashboard = () => {
  const option = {
    title: {
      text: "Turnover Analysis",
    },
    xAxis: {
      type: "category",
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: "line",
        smooth: true,
      },
    ],
  };

  return (
    <div>
      <StatCard data={statCardData} />
      <ReactECharts
        option={option}
        className="fas__echarts"
        style={{ height: 500 }}
      />
    </div>
  );
};

export default Dashboard;
