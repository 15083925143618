import { Button, Form, Input, Modal, Rate, Space, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import "./Services.css";
import { deleteAPI, getAPI, postAPI, putAPI } from "../../../../util/api";
import {
  EditOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import useNotification from "../../../../hooks/useNotification";
import useUserData from "../../../../hooks/useUserData";

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};

const Services = () => {
  const [showNotification, contextHolder] = useNotification();
  const { userData } = useUserData();

  const [tableData, setTableData] = useState();
  const getTableData = useCallback(() => {
    getAPI(`service/provider/${userData._id}`).then((data) =>
      setTableData(data)
    );
  }, [userData._id]);
  useEffect(() => {
    getTableData();
  }, [getTableData]);

  // Dialogs - Modify service data
  const [openEdit, setOpenEdit] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpenEdit(false);
      setOpenAdd(false);
      setConfirmLoading(false);
    }, 1000);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpenEdit(false);
    setOpenAdd(false);
  };

  // Edit Form Data
  const onFinishEdit = (values) => {
    putAPI("service/", { _id: form.getFieldValue("_id"), ...values }).then(
      () => {
        handleOk();
        getTableData();
        showNotification({
          type: "success",
          description: "Successfully edit a line of data",
        });
      }
    );
  };
  const showModal = (record) => {
    form.setFieldsValue(record);
    setOpenEdit(true);
  };

  // Dialogs - Add service data
  const [openAdd, setOpenAdd] = useState(false);
  // Add Form Data
  const onFinishAdd = (values) => {
    postAPI("service", { pid: userData._id, ...values }).then(() => {
      handleOk();
      getTableData();
      showNotification({
        type: "success",
        description: "Successfully add a data",
      });
    });
  };

  const onDelete = (id) => {
    deleteAPI(`service/${id}`).then(() => {
      showNotification({
        type: "success",
        description: "Successfully delete a data",
      });
    });
  };

  const columns = [
    {
      title: "Service Name",
      dataIndex: "name",
    },
    {
      title: "Image",
      dataIndex: "img",
      render: (img) => <img className="table_img" src={img} alt="img" />,
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (price) => <p>£ {price}</p>,
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Availability",
      dataIndex: "availability",
      render: (_, record) => <>{record.availability.toString()}</>,
    },
    {
      title: "Description",
      dataIndex: "desc",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <Rate disabled allowHalf defaultValue={record.rating} />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => showModal(record)}
            icon={<EditOutlined />}
          />
          <Button
            danger
            type="primary"
            onClick={() => onDelete(record._id)}
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}

      <div className="fas__services">
        <Button
          type="primary"
          onClick={() => setOpenAdd(true)}
          style={{ marginBottom: 20, width: 200 }}
          icon={<PlusCircleOutlined />}
        >
          Add New Service
        </Button>

        <Table
          columns={columns}
          dataSource={tableData}
          rowKey="_id"
          scroll={{
            x: 1300,
          }}
          pagination={false}
        />

        <Modal
          title="Add New Service"
          open={openAdd}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          footer={null}
        >
          <Form {...layout} onFinish={onFinishAdd}>
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="price" label="Price">
              <Input />
            </Form.Item>
            <Form.Item name="address" label="Address">
              <Input />
            </Form.Item>
            <Form.Item name="desc" label="Description">
              <Input />
            </Form.Item>
            <Form.Item name="category" label="Category">
              <Input />
            </Form.Item>
            <Form.Item name="img" label="Photo">
              <Input />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: 8,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                icon={<PlusCircleOutlined />}
              >
                Add New Service
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Edit Service"
          open={openEdit}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          footer={null}
        >
          <Form {...layout} onFinish={onFinishEdit} form={form}>
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="address" label="Address">
              <Input />
            </Form.Item>
            <Form.Item name="price" label="Price">
              <Input />
            </Form.Item>
            <Form.Item name="desc" label="Description">
              <Input />
            </Form.Item>
            <Form.Item name="category" label="Category">
              <Input />
            </Form.Item>
            <Form.Item name="img" label="Photo">
              <Input />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: 8,
              }}
            >
              <Button type="primary" htmlType="submit" icon={<EditOutlined />}>
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default Services;
