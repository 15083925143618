import React, { useEffect, useState } from "react";
import "./Detailtext.css";
import { Button } from "antd";
import { Rate } from "antd";
import {
  FrownOutlined,
  MehOutlined,
  ShoppingCartOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { getAPI, postAPI } from "../../../../util/api";
import useUserData from "../../../../hooks/useUserData";
import useNotification from "../../../../hooks/useNotification";

const customIcons = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};
export default function Detailtext() {
  // Get Service ID
  const { id } = useParams();
  const [serviceData, setServiceData] = useState({});

  useEffect(() => {
    getAPI(`service/${id}`).then((data) => {
      setServiceData(data);
    });
  }, [id]);

  // Get user info from localstorage
  const { userData } = useUserData();

  const navigate = useNavigate();

  const [showNotification, contextHolder] = useNotification();

  const onBooking = () => {
    postAPI("order", { sid: id, cid: userData._id }).then(() => {
      showNotification({
        type: "success",
        description: "Book Successfully!",
      });
      setTimeout(() => {
        navigate("/account/order");
      }, 2000);
    });
  };

  return (
    <>
      {contextHolder}

      <div className="fas__dpage-text">
        <h1>{serviceData.name} </h1>
        <Rate
          defaultValue={serviceData.rating}
          character={({ index }) => customIcons[index + 1]}
          disabled
          allowHalf
        />
        <p className="fas__dpage-text-title">Description:</p>
        <p>{serviceData.desc}</p>
        <h2>£ {serviceData.price}</h2>
        <p className="fas__dpage-text-title">Category:</p>
        <p>{serviceData.category}</p>
        <p className="fas__dpage-text-title">Address:</p>
        <p>{serviceData.address}</p>

        <Button
          onClick={onBooking}
          type="primary"
          style={{ marginTop: 20, width: "100%" }}
          icon={<ShoppingCartOutlined />}
        >
          Book now!
        </Button>
      </div>
    </>
  );
}
