import React, { useEffect, useState } from "react";
import { getAPI } from "../../../../util/api";
import { Table } from "antd";

export const Customers = () => {
  const columns = [
    {
      title: "Avatar",
      dataIndex: "photo",
      render: (_, record) => (
        <>
          <img className="table_img" src={record.photo} alt="Avatar" />{" "}
        </>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
  ];

  // Table data
  const [tableData, setTableData] = useState();

  useEffect(() => {
    getAPI("customer").then((data) => setTableData(data));
  }, []);

  return (
    <div id="fas__admin-customer">
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey="_id"
        pagination={false}
      />
    </div>
  );
};
