import React from "react";
import MenuUser from "../../../components/MenuUser/MenuUser";
import "./Account.css";
import { Outlet } from "react-router-dom";

export default function Account() {
  return (
    <div id="fas__account">
      <div className="fas__account-left">
        <MenuUser />
      </div>

      <div className="fas__account-right">
        <Outlet />
      </div>
    </div>
  );
}
