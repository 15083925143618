import React from "react";
import {
  SettingOutlined,
  UnorderedListOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import "./MenuUser.css";
import { useLocation, useNavigate } from "react-router-dom";
import useUserData from "../../hooks/useUserData";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem("Account Setting", "setting", <SettingOutlined />),
  getItem("My Order", "order", <UnorderedListOutlined />),
  getItem("My Favourite", "wishlist", <HeartOutlined />),
];

export default function MenuUser() {
  // Click the button of the menu to a new page
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  const menuSeleted = currentPath.split("/")[2];

  const { userData } = useUserData();

  const onClickToPage = (e) => {
    const lastSlashIndex = currentPath.lastIndexOf("/");
    const rootPath = currentPath.slice(0, lastSlashIndex);
    const targetPath = rootPath + "/" + e.key;
    navigate(targetPath);
  };

  return (
    <div className="fas__menubar">
      <h2 className="fas__menu-username">Hi, {userData.name}</h2>

      <Menu
        onClick={onClickToPage}
        style={{
          borderInlineEnd: "none",
        }}
        mode="vertical"
        items={items}
        selectedKeys={[menuSeleted]}
      />
    </div>
  );
}
