import React from "react";
import "./SelectServices.css";
import { Link } from "react-router-dom";
import ServicesData from "../Data/ServicesData";

export default function SelectServices() {
  return (
    <div className="fas__home-services">
      {ServicesData.map((service) => (
        <Link
          to={`/service_list?category=${service.name}`}
          className="fas__home-service"
          style={{ background: `url(${service.img}) center / 100%` }}
        >
          <span>{service.name}</span>
        </Link>
      ))}
    </div>
  );
}
