import React from "react";
import "./Detailspage.css";
import { Tabs } from "antd";
import Detailtext from "./Detailtext/Detailtext";
import providerInformation from "./Data/providerInformation";
import Providerintroduction from "./Providerintroduction/Providerintroduction";
import Comments from "./Comments/Comments";
import Describe from "./Describe/Describe";
import Carouselphoto from "./Carouselphoto/Carouselphoto";

function Detailspage() {
  const [piData] = React.useState(providerInformation);

  const items = [
    {
      key: "1",
      label: `Description`,
      children: <Describe />,
    },
    {
      key: "2",
      label: `Comments`,
      children: <Comments />,
    },
  ];

  return (
    <div className="fas__detailspage">
      <div className="fas__product_detail">
        <div className="fas__product_detail_photo">
          <Carouselphoto />
        </div>
        <div className="fas__product_detail_text">
          <Detailtext data={piData} />
        </div>
      </div>

      <div className="fas__dp-provider">
        <Providerintroduction data={piData} />
      </div>

      <div className="fas__describe_review">
        <Tabs centered defaultActiveKey="1" items={items} size="large" />
      </div>
    </div>
  );
}

export default Detailspage;
