import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import "./Backend.css";
import { Menu } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

const Backend = () => {
  const [menuItem, setMenuItem] = useState([]);

  // Click the button of the menu to a new page
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  const menuSeleted = currentPath.split("/")[3];

  const onClickToPage = (e) => {
    const lastSlashIndex = currentPath.lastIndexOf("/");
    const rootPath = currentPath.slice(0, lastSlashIndex);
    const targetPath = rootPath + "/" + e.key;
    navigate(targetPath);
  };

  // Add animation to each children page transition
  useEffect(() => {
    const divChild = document.querySelector(".fas__backend-main > div");
    if (divChild) {
      divChild.style.animation = "fas_fadeInUp 0.3s ease-in-out";
    }
  });

  return (
    <div id="fas__backend">
      <Navbar />
      <div className="fas__backend-container">
        <Menu
          onClick={onClickToPage}
          style={{
            width: 256,
            flexShrink: 0,
          }}
          mode="inline"
          items={menuItem}
          selectedKeys={[menuSeleted]}
        />

        <div className="fas__backend-right">
          <div className="fas__backend-main">
            <Outlet context={[setMenuItem]} />
          </div>

          <div className="fas__footer-banner">
            <div className="fas__footer-banner-copyright">
              © 2023{" "}
              <Link
                to="https://harriswong.top"
                target="_blank"
                className="fas__link-text"
              >
                Harris Wong
              </Link>
              . All rights reserved
            </div>

            <ul className="fas__footer-banner-social">
              <li>
                <TwitterOutlined />
              </li>
              <li>
                <InstagramOutlined />
              </li>
              <li>
                <FacebookOutlined />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Backend;
