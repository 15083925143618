import React, { useEffect } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import {
  CommentOutlined,
  CustomerServiceOutlined,
  MailOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const menuData = [
  getItem("Providers", "providers", <MailOutlined />),
  getItem("Customers", "customers", <UsergroupAddOutlined />),
  getItem("Reviews", "reviews", <CommentOutlined />),
  getItem("Services", "services", <CustomerServiceOutlined />),
  getItem("My Account", "account", <UserOutlined />),
];

const Admin = () => {
  const [setMenuItem] = useOutletContext();

  useEffect(() => {
    setMenuItem(menuData);
  });

  return (
    <>
      <Outlet />
    </>
  );
};

export default Admin;
