import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Input, Radio } from "antd";
import Form from "antd/es/form/Form";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SignUp.css";
import { postAPI } from "../../../util/api";
import useNotification from "../../../hooks/useNotification";

const SignUp = () => {
  const navigate = useNavigate();

  const [showNotification, contextHolder] = useNotification();

  const onFinish = (values) => {
    postAPI(`${values.role}`, values)
      .then((data) => {
        showNotification({
          type: "success",
          description: data,
        });
        setTimeout(() => navigate("/auth/login"), 2000);
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          type: "error",
          description: err.response.data || "Sign Up failed!",
          duration: 3,
        });
      });
  };

  return (
    <>
      {contextHolder}
      <Form
        name="fas__signup-form"
        className="animate__animated animate__fadeInRight"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <h1 className="fas__font-georgia">Sign Up</h1>

        <Form.Item name="role" initialValue="customer">
          <Radio.Group>
            <Radio.Button value="customer" defaultChecked>
              Customer
            </Radio.Button>
            <Radio.Button value="provider">Provider</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your User Name!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Your Name"
          />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email Address!",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email Address"
          />
        </Form.Item>

        <Form.Item
          name="pwd"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          dependencies={["pwd"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("pwd") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="fas__signup-form-button"
          >
            Sign Up
          </Button>
          Or{" "}
          <Link className="fas__link-text" to="/auth/login">
            Login
          </Link>{" "}
          now!
        </Form.Item>
      </Form>
    </>
  );
};

export default SignUp;
