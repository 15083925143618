import React from "react";
import { Card, Image } from "antd";

export default function Describe() {
  const serviceInfo = {
    name: "Pet Caring Service",
    information: `Pet Boarding: Our Pet Caring Service offers a comfortable and safe boarding facility for your pets. We provide a nurturing environment where your furry friends can stay while you're away, ensuring they receive proper care, attention, and plenty of exercise. 

    Dog Walking: Our professional dog walkers are dedicated to keeping your dogs active and happy. We offer regular dog walking services, ensuring that your furry companions get the exercise and mental stimulation they need to stay healthy and well-balanced.
    
    Pet Grooming: Our skilled groomers provide top-notch grooming services for your pets. From bathing and brushing to nail trimming and coat styling, we ensure that your pets look their best and feel refreshed after each grooming session.
    
    Pet Sitting: When you're unable to be with your pets, our pet sitters step in to provide companionship, feeding, playtime, and basic care. Whether it's a short visit or overnight stay, we offer personalized attention to meet your pets' specific needs.`,

    images: [
      "https://images.unsplash.com/photo-1615751072497-5f5169febe17?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80",
      "https://images.unsplash.com/photo-1587463272361-565200f82b33?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      "https://images.unsplash.com/photo-1612940960267-4549a58fb257?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      "https://images.unsplash.com/photo-1575859431352-39e2735a0aab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    ],
  };

  return (
    <Card title={serviceInfo.name} style={{ marginBottom: "20px" }}>
      <div style={{ margin: "20px", textAlign: "left" }}>
        <p
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            whiteSpace: "pre-line",
          }}
        >
          {serviceInfo.information}
        </p>
        <Image.PreviewGroup
          preview={{
            onChange: (current, prev) =>
              console.log(`current index: ${current}, prev index: ${prev}`),
          }}
        >
          {serviceInfo.images.map((image) => (
            <Image
              style={{
                objectFit: "cover",
              }}
              width={220}
              height={220}
              src={image}
              key={image}
            />
          ))}
        </Image.PreviewGroup>
      </div>
    </Card>
  );
}
