import React from "react";
import "./StatCard.css";

// Usage as follows:
// data = [
//   {
//     title: "",
//     number: 0,
//     icon: <UserOutlined />,
//     bgColor: "orange"
//   },
// ]
// color includes: orange, yellow, green, blue, red, grey

const StatisticCard = (props) => {
  return (
    <div id="fas__statisticcard">
      {props.data.map((data) => (
        <div key={`fas__statisticcard-${data.title}`} className={`fas__statisticcard-item fas__statisticcard-item-${data.bgColor}`}>
          <div className="fas__statisticcard-title">{data.title}</div>
          <h1>{data.number.toLocaleString()}</h1>
          {data.icon}
        </div>
      ))}
    </div>
  );
};

export default StatisticCard;
