import axios from "axios";

const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_BASE_URL
    : "https://find-a-service-api.vercel.app/";

export const getAPI = async (path) => {
  const res = await axios.get(`${API_BASE_URL}${path}`);
  return res.data;
};

export const postAPI = async (path, data) => {
  const res = await axios.post(`${API_BASE_URL}${path}`, data);
  return res.data;
};

export const putAPI = async (path, data) => {
  const res = await axios.put(`${API_BASE_URL}${path}`, data);
  return res.data;
};

export const deleteAPI = async (path) => {
  const res = await axios.delete(`${API_BASE_URL}${path}`);
  return res.data;
};
