import React from "react";
import "./Auth.css";
import wave from "../../assets/Layered Waves.svg";
import { Outlet } from "react-router-dom";
import AuthSVG from "../../assets/Auth.svg";
import Navbar from "../../components/Navbar/Navbar";

const Auth = () => {
  return (
    <div id="fas__auth">
      <Navbar />

      <div className="fas__auth-container">
        <img className="fas__auth-img" src={AuthSVG} alt="LoginSVG" />

        <Outlet />
      </div>

      <div className="fas__auth-wave animate__animated animate__slideInUp">
        <img src={wave} alt="wave" />
      </div>
    </div>
  );
};

export default Auth;
