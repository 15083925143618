import React, { useState } from "react";
import { Button, Descriptions, Form, Input } from "antd";
import useNotification from "../../../../../hooks/useNotification";
import useUserData from "../../../../../hooks/useUserData";
import { putAPI } from "../../../../../util/api";
import { useNavigate } from "react-router-dom";

const AccountSetting = () => {
  const [size] = useState("default");

  const [isShowForm, setIsShowForm] = useState(false);

  const [showNotification, contextHolder] = useNotification();

  const { userData, setUserData } = useUserData();

  const navigate = useNavigate();

  const onFinish = (values) => {
    putAPI("customer", { _id: userData._id, ...values }).then((data) => {
      setUserData({ ...data, role: userData.role });
      showNotification({
        type: "success",
        description: "Edit Successfully!",
      });
      setTimeout(() => {
        navigate(0);
      }, 2000);
    });
  };

  return (
    <>
      {contextHolder}

      <Button
        style={{ float: "right" }}
        type="primary"
        onClick={() => setIsShowForm((prev) => !prev)}
      >
        {isShowForm ? "Cancel" : "Edit Info"}
      </Button>

      <h1 className="fas__borderbottom">Account Setting</h1>

      {isShowForm ? (
        <Form
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          initialValues={userData}
          onFinish={onFinish}
          autoComplete="off"
          style={{ maxWidth: 600, margin: "0 auto" }}
        >
          <Form.Item
            label="User Name"
            name="name"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="pwd"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item label="Description" name="desc">
            <Input />
          </Form.Item>

          <Form.Item label="Phone" name="phone">
            <Input />
          </Form.Item>

          <Form.Item label="Address" name="address">
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Edit Info
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Descriptions
          bordered
          size={size}
          column={1}
          style={{ width: "80%", margin: "0 auto" }}
        >
          <Descriptions.Item label="User Name">
            {userData.name}
          </Descriptions.Item>
          <Descriptions.Item label="Email">{userData.email}</Descriptions.Item>
          <Descriptions.Item label="Address">
            {userData.address}
          </Descriptions.Item>
          <Descriptions.Item label="Description">
            {userData.desc}
          </Descriptions.Item>
          <Descriptions.Item label="Phone">{userData.phone}</Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
};

export default AccountSetting;
