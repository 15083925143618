import { Rate, Space, Table, Button } from "antd";
import React, { useEffect, useState } from "react";
import { deleteAPI, getAPI } from "../../../../util/api";
import { DeleteOutlined } from "@ant-design/icons";
import useNotification from "../../../../hooks/useNotification";

const Reviews = () => {
  const [showNotification, contextHolder] = useNotification();

  const [tableData, setTableData] = useState();
  const getTableData = () => {
    getAPI("review").then((data) => setTableData(data));
  };
  useEffect(() => {
    getTableData();
  }, []);

  // Delete request
  const onDelete = (_id) => {
    deleteAPI(`review/${_id}`).then(() => {
      showNotification({
        type: "success",
        description: "Delete one review successfully",
      });
    });
  };

  // table column
  const columns = [
    {
      title: "Provider Name",
      dataIndex: ["oid", "sid", "pid", "name"],
    },
    {
      title: "Service Name",
      dataIndex: ["oid", "sid", "name"],
    },
    {
      title: "Customer Name",
      dataIndex: ["oid", "cid", "name"],
    },
    {
      title: "Review",
      dataIndex: "content",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <Rate disabled allowHalf defaultValue={record.rating} />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_id) => (
        <Space size="middle">
          <Button
            type="primary"
            danger
            onClick={() => onDelete(_id)}
            shape="circle"
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ];
  return (
    <>
      {contextHolder}

      <div>
        <Table
          columns={columns}
          dataSource={tableData}
          rowKey="_id"
          pagination={false}
        />
      </div>
    </>
  );
};

export default Reviews;
