import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Frontend from "../pages/Frontend/Frontend";
import Home from "../pages/Frontend/Home/Home";
import Auth from "../pages/Auth/Auth";
import Login from "../pages/Auth/Login/Login";
import SignUp from "../pages/Auth/SignUp/SignUp";
import Backend from "../pages/Backend/Backend";
import Dashboard from "../pages/Backend/Provider/Dashboard/Dashboard";
import Provider from "../pages/Backend/Provider/Provider";
import Admin from "../pages/Backend/Admin/Admin";
import Orders from "../pages/Backend/Provider/Orders/Orders";
import Providers from "../pages/Backend/Admin/Providers/Providers";
import AdminReviews from "../pages/Backend/Admin/Reviews/Reviews";
import ProviderReviews from "../pages/Backend/Provider/Reviews/Reviews";
import ServicesProvider from "../pages/Backend/Provider/Services/Services";
import ServicesAdmin from "../pages/Backend/Admin/Services/Services";
import AccountProvider from "../pages/Backend/Provider/Account/Account";
import AccountAdmin from "../pages/Backend/Admin/Account/Account";
import NotFound from "../components/404/404";
import Details from "../pages/Frontend/Details_Page/Detailspage";
import CustomerOrders from "../pages/Frontend/Account/components/Orders/Orders";
import CustomerWishList from "../pages/Frontend/Account/components/CustomerWishList/CustomerWishList";
import Invoice from "../pages/Frontend/Account/components/Invoice/Invoice";
import Account from "../pages/Frontend/Account/Account";
import AccountSetting from "../pages/Frontend/Account/components/AccountSetting/AccountSetting";
import { Customers } from "../pages/Backend/Admin/Customers/Customers";
import ServiceList from "../pages/Frontend/ServiceList/ServiceList";

// Configure page routes here
const routes = [
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/",
    element: <Frontend />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "details/:id",
        element: <Details />,
      },
      {
        path: "invoice",
        element: <Invoice />,
      },
      {
        path: "service_list",
        element: <ServiceList />,
      },
      {
        path: "account",
        element: <Account />,
        children: [
          {
            path: "",
            element: <Navigate to="/account/setting" />,
          },
          {
            path: "setting",
            element: <AccountSetting />,
          },
          {
            path: "order",
            element: <CustomerOrders />,
          },
          {
            path: "wishlist",
            element: <CustomerWishList />,
          },
        ],
      },
    ],
  },
  {
    path: "/backend",
    element: <Backend />,
    children: [
      {
        path: "",
        element: <Navigate to="/backend/provider/dashboard" />,
      },
      {
        path: "provider",
        element: <Provider />,
        children: [
          {
            path: "",
            element: <Navigate to="/backend/provider/dashboard" />,
          },
          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "orders",
            element: <Orders />,
          },
          {
            path: "services",
            element: <ServicesProvider />,
          },
          {
            path: "reviews",
            element: <ProviderReviews />,
          },
          {
            path: "account",
            element: <AccountProvider />,
          },
        ],
      },
      {
        path: "admin",
        element: <Admin />,
        children: [
          {
            path: "",
            element: <Navigate to="/backend/admin/providers" />,
          },
          {
            path: "providers",
            element: <Providers />,
          },
          {
            path: "reviews",
            element: <AdminReviews />,
          },
          {
            path: "services",
            element: <ServicesAdmin />,
          },
          {
            path: "account",
            element: <AccountAdmin />,
          },
          {
            path: "customers",
            element: <Customers />,
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        path: "",
        element: <Navigate to="/auth/login" />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },
    ],
  },
];

const Router = () => {
  const router = useRoutes(routes);
  return <>{router}</>;
};

export default Router;
