import React from "react";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input, Radio } from "antd";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { postAPI } from "../../../util/api";
import useNotification from "../../../hooks/useNotification";
import useUserData from "../../../hooks/useUserData";

const Login = () => {
  const navigate = useNavigate();

  const [showNotification, contextHolder] = useNotification();

  const { setUserData } = useUserData();

  // login
  const onFinish = (values) => {
    postAPI(`${values.role}/login`, values)
      .then((data) => {
        setUserData({ ...data, role: values.role });
        showNotification({
          type: "success",
          description: `Hi, ${data.name}. Welcome to Find A Service!`,
        });
        setTimeout(() => {
          if (values.role === "customer") {
            navigate("/", { replace: true });
          } else {
            navigate(`/backend/${values.role}`, { replace: true });
          }
        }, 2000);
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          type: "error",
          description: err.response.data,
          duration: 3,
        });
      });
  };

  return (
    <>
      {contextHolder}
      <Form
        name="fas__login-form"
        className="animate__animated animate__fadeInRight"
        onFinish={onFinish}
      >
        <h1 className="fas__font-georgia">Login</h1>

        <Form.Item name="role" initialValue="customer">
          <Radio.Group>
            <Radio.Button value="customer">Customer</Radio.Button>
            <Radio.Button value="provider">Provider</Radio.Button>
            <Radio.Button value="admin">Admin</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="email"
          initialValue="123@gmail.com"
          rules={[
            {
              required: true,
              message: "Please input your Email",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>

        <Form.Item
          name="pwd"
          initialValue="123"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="fas__login-form-button"
          >
            Log in
          </Button>
          Or{" "}
          <Link className="fas__link-text" to="/auth/signup">
            Sign up
          </Link>{" "}
          now!
        </Form.Item>
      </Form>
    </>
  );
};

export default Login;
