import { Table, Space, Tag, Button } from "antd";
import React, { useEffect, useState } from "react";
import { getAPI, putAPI } from "../../../../util/api";
import { CheckOutlined, DeleteOutlined, StopOutlined } from "@ant-design/icons";
import useNotification from "../../../../hooks/useNotification";

const Providers = () => {
  const [tableData, setTableData] = useState();

  const getTableData = () => {
    getAPI("provider").then((data) => {
      setTableData(data);
    });
  };

  useEffect(() => {
    getTableData();
  }, []);

  const [showNotification, contextHolder] = useNotification();

  // delete
  const onDelete = (id) => {
    getAPI(`provider/deleteprovider?id=${id}`).then(() => {
      showNotification({
        type: "success",
        description: "Delete Successfully!",
      });
      getTableData();
    });
  };

  const onApprove = (_id, permitted) => {
    putAPI("provider", { _id, permitted }).then(() => {
      showNotification({
        type: "success",
        description: "You successfully approved this provider!",
      });
      getTableData();
    });
  };

  const columns = [
    {
      title: "Provider Name",
      dataIndex: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Description",
      dataIndex: "desc",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, { permitted }) => {
        let color;
        if (permitted) {
          color = "green";
        } else {
          color = "default";
        }
        return (
          <Tag color={color}>{permitted ? "Approved" : "Not Approved"}</Tag>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, { _id, permitted }) => (
        <Space size="middle">
          <Button
            type="primary"
            style={permitted ? {} : { backgroundColor: "green" }}
            onClick={() => onApprove(_id, !permitted)}
            icon={permitted ? <StopOutlined /> : <CheckOutlined />}
            shape="circle"
            danger={permitted}
          />
          <Button
            type="primary"
            danger
            onClick={() => onDelete(_id)}
            shape="circle"
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div className="fas__admin-providers">
        <Table
          columns={columns}
          dataSource={tableData}
          rowKey="_id"
          pagination={false}
        />
      </div>
    </>
  );
};

export default Providers;
