import React from "react";
import { Carousel} from "antd";
export default function Carouselphoto() {
  const contentStyle = {
    height: "400px",
    width: "600px",
    color: "#fff",
    textAlign: "center",
    background: "#364d79",
  };

  const [dotPosition] = React.useState("right");

  const photo = [
    "https://images.unsplash.com/photo-1537123547273-e59f4f437f1b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    "https://images.unsplash.com/photo-1520741412100-24cd6c2832e5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",

    "https://images.unsplash.com/photo-1611469023115-405af35a5f80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    "https://images.unsplash.com/photo-1553776590-89774e24b34a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
  ];

  return (
    <div>
      <Carousel dotPosition={dotPosition} autoplay>
        {photo.map((photo, index) => (
          <div key={index}>
            <img src={photo} alt="" style={contentStyle} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
